<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        editing
          ? `Editer le groupe de vue de partenaires : ${partnerViewsGroup.name}`
          : "Créer un nouveau groupe de vue de partenaires"
      }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="partnerViewsGroupForm">
            <v-row>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="partnerViewsGroup.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    v-model="partnerViewsGroup.code"
                    outlined
                    dense
                    :rules="[required]"
                    label="Code"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="partnerViewsGroup.partnerViews"
                  :items="partnerViewsList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Vues de partenaires"
                  multiple
                  chips
                  dense
                  deletable-chips
                  item-text="label"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="savePartnerViewsGroup"
                >
                  Enregistrer le groupe</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'admin-list-group-partner-view' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, integerValidator } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";

export default {
  name: "PartnerViewsGroupCreateOrUpdate",
  async created() {
    if ("id" in this.$route.params && this.$route.params.id) {
      await this.getPartnerViewsGroupById(this.$route.params.id);
      this.editing = true;
      this.partnerViewsGroup.pk = this.$route.params.id;
    } else {
      this.editing = false;
    }
    await this.$store.dispatch("premium/getPartnerViewsGroupList");

    const {
      status,
      data: { items },
    } = await axios.get("/partner-views/");
    if (status === 200) {
      this.partnerViewsList = items;
    }
  },
  data() {
    return {
      required,
      integerValidator,
      editing: false,
      partnerViewsList: [],
      partnerViewsGroup: {
        pk: -1,
        partnerViews: [],
        name: "",
        code: "",
      },
    };
  },
  methods: {
    async savePartnerViewsGroup() {
      const isFormValid = this.$refs.partnerViewsGroupForm.validate();
      if (!isFormValid) return;
      const payload = {
        name: this.partnerViewsGroup.name,
        code: this.partnerViewsGroup.code,
        partnerViews: this.partnerViewsGroup.partnerViews.join(","),
      };

      if (this.editing) {
        const { status } = await axios.put(
          `/partner-view-groups/${this.partnerViewsGroup.pk}`,
          payload
        );
        if (status === 200) {
          await router.push({ name: "admin-list-group-partner-view" });
        }
      } else {
        const { status } = await axios.post("/partner-view-groups/", payload);
        if (status === 200) {
          await router.push({ name: "admin-list-group-partner-view" });
        }
      }
    },
    async getPartnerViewsGroupById(id) {
      const { status, data } = await axios.get(`/partner-view-groups/${id}`);
      if (status === 200) {
        this.partnerViewsGroup = {
          pk: data.pk,
          name: data.name,
          code: data.code,
          partnerViews: data.partnerViews,
        };
      }
    },
    normalize(s) {
      return s
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-z0-9 -]/gi, "")
        .replace(/[\s.*]/gi, "");
    },
  },
  watch: {
    "partnerViewGroup.name"(v) {
      // if (!this.siteGroup.code) {
      this.partnerViewsGroup.code = this.normalize(v)
        .replace(/[aeiou]/gi, "")
        .substring(0, 5)
        .toLowerCase();
      // }
    },
  },
};
</script>

<style scoped></style>
